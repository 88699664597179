<template>
  <div class="admin dashboard">

    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/waiting_lines">Warteschlangen</router-link>
        <p>{{ waiting_line.name }}</p>
      </h1>
      <div class="meta">
        <a @click="reload_calendar" class="button button-red" style="margin-left:20px;padding: 0.5rem 1rem">
          <svg style="margin-right:0" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-rotate-right" class="svg-inline--fa fa-arrow-rotate-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M496 48V192c0 17.69-14.31 32-32 32H320c-17.69 0-32-14.31-32-32s14.31-32 32-32h63.39c-29.97-39.7-77.25-63.78-127.6-63.78C167.7 96.22 96 167.9 96 256s71.69 159.8 159.8 159.8c34.88 0 68.03-11.03 95.88-31.94c14.22-10.53 34.22-7.75 44.81 6.375c10.59 14.16 7.75 34.22-6.375 44.81c-39.03 29.28-85.36 44.86-134.2 44.86C132.5 479.9 32 379.4 32 256s100.5-223.9 223.9-223.9c69.15 0 134 32.47 176.1 86.12V48c0-17.69 14.31-32 32-32S496 30.31 496 48z"></path>
          </svg>
        </a>
        <a @click="reshuffle" class="button button-red" style="margin-left:20px;padding: 0.5rem 1rem">
          <svg style="margin-right:0" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shuffle" class="svg-inline--fa fa-shuffle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M424.1 287c-15.13-15.12-40.1-4.426-40.1 16.97V352H336L153.6 108.8C147.6 100.8 138.1 96 128 96H32C14.31 96 0 110.3 0 128s14.31 32 32 32h80l182.4 243.2C300.4 411.3 309.9 416 320 416h63.97v47.94c0 21.39 25.86 32.12 40.99 17l79.1-79.98c9.387-9.387 9.387-24.59 0-33.97L424.1 287zM336 160h47.97v48.03c0 21.39 25.87 32.09 40.1 16.97l79.1-79.98c9.387-9.391 9.385-24.59-.0013-33.97l-79.1-79.98c-15.13-15.12-40.99-4.391-40.99 17V96H320c-10.06 0-19.56 4.75-25.59 12.81L254 162.7L293.1 216L336 160zM112 352H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c10.06 0 19.56-4.75 25.59-12.81l40.4-53.87L154 296L112 352z"></path>
          </svg>
        </a>
        <a @click="open_create_modal" class="button button-red" style="margin-left:20px">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
          </svg>
          <span>Soforttermin erstellen</span>
        </a>
      </div>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="calendars cf">
        <div class="current-time" ref="currentTime" :style="current_time_style"></div>
        <div class="cal-times">
          <header class="cal-header"></header>
          <div v-for="time in calendar_times" v-bind:key="time" class="cal-time">
            {{ time }}
          </div>
        </div>
        <div class="cal-body cf">
          <div v-for="counter in calendar_counters" v-bind:key="counter" class="cal-counters">
            <header class="cal-header">
              <p>
                <span v-if="counter.status == 'disabled'" class="waiting-status status-hidden">
                  <span class="status-icon" style="background:red"></span>
                </span>
                <span v-if="counter.status == 'enabled'" class="waiting-status status-visible">
                  <span class="status-icon"></span>
                </span>
                <span>{{ counter.name }}</span>
                <span v-if="counter.name != 'Nicht zugeordnet'" style="margin-left:5px">
                  <svg v-if="counter.status == 'disabled'" @click="change_counter(counter)" aria-hidden="true" style="width:20px;height:auto;display:block;" focusable="false" data-prefix="far" data-icon="toggle-off" class="svg-inline--fa fa-toggle-off" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M384 64H192C85.96 64 0 149.1 0 256s85.96 192 192 192h192c106 0 192-85.96 192-192S490 64 384 64zM384 400H192c-79.4 0-144-64.6-144-144S112.6 112 192 112h192c79.4 0 144 64.6 144 144S463.4 400 384 400zM192 160C138.1 160 96 202.1 96 256s42.98 96 96 96s96-42.98 96-96S245 160 192 160zM192 304C165.5 304 144 282.5 144 256S165.5 208 192 208S240 229.5 240 256S218.5 304 192 304z"></path>
                  </svg>
                  <svg v-else @click="change_counter(counter)" style="width:20px;height:auto;display:block;" aria-hidden="true" focusable="false" data-prefix="far" data-icon="toggle-on" class="svg-inline--fa fa-toggle-on" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M384 64H192c-106 0-192 85.96-192 191.1s85.96 192 192 192h192c106 0 192-85.96 192-192S490 64 384 64zM384 400H192c-79.4 0-144-64.6-144-144S112.6 112 192 112h192c79.4 0 144 64.6 144 144S463.4 400 384 400zM384 160c-53.02 0-96 42.98-96 96s42.98 96 96 96s96-42.98 96-96S437 160 384 160z"></path>
                  </svg>
                </span>
              </p>
            </header>
            <div v-for="time in counter.times" v-bind:key="time" class="cal-time">
              <ul class="cal-events">
                <li v-for="appointment in time.appointments" v-bind:key="appointment.id" @click="open_modal(appointment)">
                  <div :class="'cal-event event-'+appointment.status" :style="'position:absolute;top:'+appointment.margin_top+'px;height:'+appointment.height+'px'">
                    <h3>{{ appointment.first_name }} {{ appointment.last_name }}</h3>
                    <p>
                      {{ appointment.time_formatted }} / <span v-if="appointment.actual_duration">{{ appointment.actual_duration }}</span><span v-else>{{ appointment.duration }}</span> min. /
                      <span v-if="appointment.appointment_type == 'planned'">T{{ appointment.appointment_number }}</span>
                      <span v-if="appointment.appointment_type == 'spontaneous'">A{{ appointment.appointment_number }}</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div @click="scroll_to_top" class="scroll-to-top">
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"/>
          </svg>
        </p>
      </div>

    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add">
      <svg @click="modal_add = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Soforttermin hinzufügen</h3>
      </div>
      <div class="modal-body">

      <div class="row row-gutter-20">
        <div class="col-20">
          <div class="row row-gutter-20">
            <div class="col-8">
              <div class="form-wrap select-wrap">
                <label for="category_id">Standort</label>
                <select v-model="appointment.location_id" class="form-input">
                  <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-8">
              <div class="form-wrap select-wrap">
                <label for="category_id">Kategorie</label>
                <select v-model="appointment.category_id" class="form-input">
                  <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-8">
              <div class="form-wrap select-wrap">
                <label for="category_id">Leistung</label>
                <select v-model="appointment.service_id" class="form-input">
                  <option v-for="service in services" v-bind:key="service.id" v-bind:value="service.id">{{ service.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-wrap">
            <label for="service_amount">Anzahl</label>
            <input v-model="appointment.service_amount" class="form-input input-grey" type="text" name="service_amount" id="service_amount">
          </div>
        </div>
      </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name" class="form-label">Vorname</label>
              <input v-model="appointment.first_name" class="form-input input-grey" type="text" name="first_name" id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name" class="form-label">Nachname</label>
              <input v-model="appointment.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
            </div>
          </div>
        </div>

        <div class="form-wrap select-wrap">
          <label for="category_id">Schalter</label>
          <select v-model="appointment.counter_id" class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}</option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_appointment" class="button button-red button-100">Soforttermin erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_edit">
      <svg @click="modal_edit = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <router-link :to="'/admin/appointments/'+selected_appointment.id">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up-right-from-square" class="modal-link svg-inline--fa fa-arrow-up-right-from-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor" d="M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM502.6 9.367C496.8 3.578 488.8 0 480 0h-160c-17.67 0-31.1 14.32-31.1 31.1c0 17.67 14.32 31.1 31.99 31.1h82.75L178.7 290.7c-12.5 12.5-12.5 32.76 0 45.26C191.2 348.5 211.5 348.5 224 336l224-226.8V192c0 17.67 14.33 31.1 31.1 31.1S512 209.7 512 192V31.1C512 23.16 508.4 15.16 502.6 9.367z"></path>
        </svg>
      </router-link>
      <div class="modal-head">
        <h3>Termin bearbeiten</h3>
      </div>
      <div class="modal-body">

        <a v-if="!pdf_loading" @click="download_pdf" class="button button-red button-100" style="margin-bottom:20px">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="qrcode" class="svg-inline--fa fa-qrcode fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M0 224h192V32H0v192zM64 96h64v64H64V96zm192-64v192h192V32H256zm128 128h-64V96h64v64zM0 480h192V288H0v192zm64-128h64v64H64v-64zm352-64h32v128h-96v-32h-32v96h-64V288h96v32h64v-32zm0 160h32v32h-32v-32zm-64 0h32v32h-32v-32z"></path>
          </svg>
          <span>Ausdrucken</span>
        </a>
        <div v-else class="loading-wrap" style="display:block;padding:0 0 20px 0;width:100%">
          <span class="loading-spinner" style="margin:auto"></span>
        </div>

        <p style="margin:0 0 5px 0;font-size:14px">Leistungen</p>

        <p v-for="service in selected_appointment.services" v-bind:key="service.id" style="margin: 0 0 5px 0">
          <span v-if="service.service_amount">{{ service.service_amount }}x </span><span v-if="service.location_name">{{ service.location_name }} / </span><span v-if="service.category_name">{{ service.category_name }} / </span><span v-if="service.service_name">{{ service.service_name }}</span>
        </p>

        <div class="row row-gutter-20" style="margin-top:10px">
          <div class="col-8">
            <div class="form-wrap select-wrap">
              <label for="category_id">Standort</label>
              <select v-model="selected_appointment.location_id" class="form-input">
                <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-8">
            <div class="form-wrap select-wrap">
              <label for="category_id">Kategorie</label>
              <select v-model="selected_appointment.category_id" class="form-input">
                <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-8">
            <div class="form-wrap select-wrap">
              <label for="category_id">Leistung</label>
              <select v-model="selected_appointment.service_id" class="form-input">
                <option v-for="service in services" v-bind:key="service.id" v-bind:value="service.id">{{ service.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name" class="form-label">Vorname</label>
              <input v-model="selected_appointment.first_name" class="form-input input-grey" type="text" name="first_name" id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name" class="form-label">Nachname</label>
              <input v-model="selected_appointment.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
            </div>
          </div>
        </div>

        <div class="form-wrap select-wrap">
          <label for="category_id">Status</label>
          <select v-model="selected_appointment.status" class="form-input">
            <option key="created" value="created">Reserviert</option>
            <option key="checked_in" value="checked_in">Eingecheckt</option>
            <option key="called" value="called">Aufgerufen</option>
            <option key="running" value="running">Läuft</option>
            <option key="canceled" value="canceled">Storniert</option>
            <option key="completed" value="completed">Fertig</option>
            <option key="paused" value="paused">Pausiert</option>
          </select>
        </div>

        <div class="form-wrap select-wrap">
          <label for="category_id">Schalter</label>
          <select v-model="selected_appointment.counter_id" class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}</option>
            <option key="" value="">Kein Schalter</option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="update_appointment" class="button button-red button-100">Termin speichern ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>


export default {
  name: 'admin_waiting_line',
  data () {
    return {
      pdf_loading: false,
      modal_add: false,
      modal_edit: false,
      loading: true,
      user_id: null,
      user: {},
      user_location: null,
      locations: [],
      categories: [],
      services: [],
      counters: [],
      waiting_line: {},
      appointment: {
        appointment_type: "spontaneous",
        status: "checked_in"
      },
      selected_appointment: {},
      appointments: [],
      calendar_times: [],
      calendar_counters: [],
      calendars: [],
      current_time_style: "",
      current_time_interval: null
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/waiting_lines/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.waiting_line = response.data.waiting_line;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments', {
        params: {
          date: new Date(),
          waiting_line_id: this.$route.params.uuid
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.appointments = response.data.appointments;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user_id = response.data.user.id;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.user_id, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
        if (this.user.locations && this.user.locations.length > 0) {
          this.user_location = this.user.locations[0].id;
        }
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
      /* SET CURRENT TIME */
      // 1h = 300px
      // Time - 7h + 50px
      var today = new Date();
      var border_top = ((today.getHours()-7)*540)+50
      border_top = border_top+(today.getMinutes()/60)*540
      this.current_time_style = "position:absolute;top:"+border_top+"px;"
      this.loading = false;
      setTimeout(function(){
        if (border_top > 700) {
          window.scrollTo(0,border_top-200);
        }
      }, 200);
    },
    get_current_time() {
      var today = new Date();
      var border_top = ((today.getHours()-7)*540)+50
      border_top = border_top+(today.getMinutes()/60)*540
      this.current_time_style = "position:absolute;top:"+border_top+"px;"
    },
    async reshuffle() {
      this.loading = true;
      await this.$http.post(process.env.VUE_APP_BASE_API+'/admin/waiting_lines/'+this.$route.params.uuid+'/reshuffle', {}, { headers: { Authorization: this.$store.getters.get_token } })
      this.get_data();
      this.init_cal();
      this.loading = false;
    },
    async init_cal() {
      this.calendar_times = [];
      this.calendar_counters = [];
      this.calendars = [];
      var times = [];
      for (let i = 0; i <= 24; i += 1) {
        if (i > 6 && i < 20) {
          times.push(i+":00 Uhr");
        }
      }
      this.calendar_times = times;
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/counters', {
        params: {
          waiting_line_id: this.$route.params.uuid
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        this.counters = response.data.counters;
        var counters = response.data.counters;
        counters.forEach(counter => {
          var times = [];
          for (let i = 0; i <= 24; i += 1) {
            if (i > 6 && i < 20) {
              times.push({
                val: i,
                appointments: []
              });
            }
          }
          this.calendar_counters.push({
            id: counter.id,
            name: counter.name,
            times: times,
            status: counter.status,
            appointments: []
          })

        })
        var times = [];
        for (let i = 0; i <= 24; i += 1) {
          if (i > 6 && i < 20) {
            times.push({
              val: i,
              appointments: []
            });
          }
        }
        this.calendar_counters.push({
          id: "",
          name: "Nicht zugeordnet",
          times: times,
          appointments: []
        })
      })
      this.get_appointments();
    },
    async get_appointments() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments', {
        params:  {
          date: new Date(),
          waiting_line_id: this.$route.params.uuid
        },
        headers: { Authorization: this.$store.getters.get_token }
      })
      .then(response => {
        response.data.appointments.forEach(appointment => {
          if (appointment.counter_id) {
            this.calendar_counters.forEach(counter => {
              if (counter.id == appointment.counter_id) {
                counter.times.forEach(time => {
                  if (parseInt(appointment.time_formatted) == time.val) {
                    var app_time = null;
                    var hours = null;
                    var minutes = null;
                    var duration = null;
                    if (appointment.actual_duration) {

                      app_time = new Date(appointment.time).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }).split(':');

                      if (appointment.started_at) {
                        app_time = new Date(appointment.started_at).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }).split(':');
                      }

                      if (appointment.time_estimated) {
                        app_time = new Date(appointment.time_estimated).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }).split(':');
                      }

                      if (app_time.length == 2) {
                        hours = (app_time[0]-7)*540;
                        minutes = app_time[1]*9;
                        appointment.margin_top = hours+minutes+50;
                      } else {
                        appointment.margin_top = 50;
                      }

                      duration = 9*appointment.actual_duration;
                      if (duration < 45) {
                        duration = 45;
                      }
                      appointment.height = duration;

                    } else {

                      app_time = new Date(appointment.time).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }).split(':');

                      if (appointment.started_at) {
                        app_time = new Date(appointment.started_at).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }).split(':');
                      }

                      if (appointment.time_estimated) {
                        app_time = new Date(appointment.time_estimated).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' }).split(':');
                      }

                      if (app_time.length == 2) {
                        hours = (app_time[0]-7)*540;
                        minutes = app_time[1]*9;
                        appointment.margin_top = hours+minutes+50;
                      } else {
                        appointment.margin_top = 50;
                      }

                      duration = 9*appointment.duration;
                      if (duration < 45) {
                        duration = 45;
                      }
                      appointment.height = duration;

                    }
                    time.appointments.push(appointment);
                  }
                })
              }
            });
          } else {
            this.calendar_counters.forEach(counter => {
              if (counter.id == "") {
                counter.times.forEach(time => {
                  if (parseInt(appointment.time_formatted) == time.val) {
                    if (appointment.duration <= 15) {
                      appointment.height = 45;
                    } else if (appointment.duration <= 20) {
                      appointment.height = 70;
                    } else if (appointment.duration > 20) {
                      appointment.height = 95;
                    }
                    time.appointments.push(appointment);
                  }
                })
              }
            });
          }
        });
      })
    },
    create_appointment() {

      var services = []
      var service_obj = {
        service_id: this.appointment.service_id
      }
      if (this.appointment.service_amount) {
        service_obj.service_amount = this.appointment.service_amount;
      }
      services.push(service_obj);
      this.appointment.services_attributes = services;

      this.loading = true;
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/appointments', this.appointment, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.init_cal();
        this.modal_add = false;
        this.appointment = {
          appointment_type: "spontaneous",
          status: "checked_in"
        };
        this.loading = false;
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
        this.loading = false;
      });
    },
    open_modal(appointment) {
      this.selected_appointment = appointment;
      this.modal_edit = true;
    },
    update_appointment() {
      this.loading = true;
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.selected_appointment.id, this.selected_appointment, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.init_cal();
        this.selected_appointment = {};
        this.modal_edit = false;
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
        this.loading = false;
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
        this.loading = false;
      });
    },
    change_counter(counter) {
      this.loading = true;
      if (counter.status == "enabled") {
        this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/counters/'+counter.id, {
          status: "disabled"
        }, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.init_cal();
          this.$notify({
            title: "Schalter erfolgreich geschlossen.",
            type: "success"
          });
          this.loading = false;
        })
      } else {
        this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/counters/'+counter.id, {
          status: "enabled"
        }, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.init_cal();
          this.$notify({
            title: "Schalter erfolgreich geöffnet.",
            type: "success"
          });
          this.loading = false;
        })
      }
    },
    download_pdf() {
      this.pdf_loading = true;
      return(this.$http.get(process.env.VUE_APP_BASE_API+'/admin/appointments/'+this.selected_appointment.id+'/pdf', { responseType: 'blob', headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          var pdf_name = "Termin-"+this.selected_appointment.appointment_number+".pdf"
          link.download = pdf_name
          link.click()
          URL.revokeObjectURL(link.href);
          this.pdf_loading = false;
      }))
    },
    reload_calendar() {
      this.loading = true;
      this.get_data();
      this.init_cal();
    },
    open_create_modal() {
      this.appointment = {
        appointment_type: "spontaneous",
        status: "checked_in"
      };
      if (this.user_location) {
        this.appointment.location_id = this.user_location;
      }
      this.modal_add = true;
    },
    scroll_to_top() {
      window.scrollTo(0, 0);
    }
  },
  mounted () {
    this.get_data();
    this.init_cal();

    var _this = this;
    this.current_time_interval = setInterval(function () {
      _this.get_current_time();
    }, 1000);
  },
  unmounted () {
    clearInterval(this.current_time_interval);
  },
  watch: {
    'appointment.location_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: this.appointment.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
    },
    'appointment.category_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: this.appointment.category_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.services = response.data.services;
      })
    },
    'selected_appointment.location_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/categories', { params: { location_id: this.appointment.location_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.categories = response.data.categories;
      })
    },
    'selected_appointment.category_id': function() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/admin/services', { params: { category_id: this.appointment.category_id }, headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.services = response.data.services;
      })
    }
  }
}
</script>

<style lang="scss">

  .full-calendar {
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

    /*.fc-theme-standard .fc-scrollgrid {
      border: 0;
    }*/

    .fc-scrollgrid-section.fc-scrollgrid-section-header  {
      display: none;
    }

    /*.fc-theme-standard td,
    .fc-theme-standard th {
      border: none;
    }*/

    /*.fc-theme-standard tr {
      border: 1px solid #EAEAEA;
    }*/

    .fc .fc-timegrid-col.fc-day-today {
      background-color: initial;
    }


  }

  .calendars {
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    position: relative;

    .current-time {
      width: 100%;
      height: 4px;
      background: red;
    }

    .cal-header {
      width: 100%;
      height: 50px;
      background: #f1f1f1;
      color: #1A1D1C;

      p {
        margin: 0;
        padding: 18px;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        color: #979797;
      }
    }

    .cal-times {
      float: left;
      width: 100px;
      border-right: 1px solid #f1f1f1;

      .cal-time {
        min-height: 540px;
        border-bottom: 1px solid #f1f1f1;
        font-size: 14px;
        color: #8C8C8C;
        padding: 10px;
        text-align: right;
      }
    }

    .cal-body {
      float: left;
      width: calc(100% - 100px);
      display: flex;
      align-items: stretch;

      .cal-counters {
        border-right: 1px solid #f1f1f1;
        width: 150px;
        flex: 1;
        position: relative;

        .cal-header {

          .waiting-status {
            width: 10px;
            height: 10px;
            margin-right: 5px;

            .status-icon {
              display: block;
            }
          }
        }
      }

      .cal-header {
        min-width: 140px;
      }

      .cal-time {
        min-height: 540px;
        border-bottom: 1px solid #f1f1f1;
        padding: 2px;
      }

      .cal-events {
        margin: 0;
        padding: 0;

        li {
          list-style-type: none;
          margin-bottom: 2px;
          cursor: pointer;

          .cal-event {
            width: 97%;
            border: 1px solid #f1f1f1;
            border-radius: 5px;
            background: #f9f9f9;
            padding: 8px;
            font-size: 12px;
            cursor: pointer;

            h3 {
              margin: 0 0 3px 0;
              font-weight: 600;
              font-size: 12px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            p {
              margin: 0;
              font-size: 10px;
            }
          }

          .cal-event:hover {
            z-index: 999;
          }

          .event-created {
            background: #f1f1f1;
            color: #444;
            z-index: 1;
            border: 1px solid #ccc;
          }

          .event-checked_in {
            width: 92%;
            background: #1A1D1C;
            color: #fff;
            z-index: 5;
          }

          .event-running {
            background: #FFB40A;
            color: #fff;
            z-index: 5;
          }

          .event-canceled {
            background: #CC0000;
            color: #fff;
            z-index: 5;
          }

          .event-completed {
            background: #07B400;
            color: #fff;
            z-index: 5;
          }

          .event-paused {
            background: #CC0000;
            color: #fff;
            z-index: 5;
          }

          .event-called {
            background: #000882;
            color: #fff;
            z-index: 5;
          }
        }

        /*li:hover {
          opacity: 0.9;
        }*/

        li:last-child {
          margin-bottom: 0;
        }
      }
    }

    .cal-header {

      p > span {
        display: inline-block;
        vertical-align: middle;

        svg {
          cursor: pointer;
        }

        svg:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .scroll-to-top {
    position: fixed;
    right: 20px;
    bottom: 40px;
    width: 40px;
    height: 40px;
    background: #9C2128;
    border-radius: 5px;
    cursor: pointer;

    p {
      margin: 0;
      padding: 8px 8px 0 8px;

      svg {
        width: 24px;
        height: 24px;
        color: #fff;
        fill: #fff;
      }
    }
  }

  .scroll-to-top:hover {
    background: #7B1B21;
  }

</style>
